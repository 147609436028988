import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCalendarMinus,
  faCalendarPlus,
  faDumbbell,
  faCalendar,
  faGauge,
  faSearch,
  faCircleInfo,
  faPencil,
  faTrashCan,
  faPlus,
  faArrowRight,
  faTable,
  faArrowUpRightFromSquare,
  faFilter,
  faRefresh,
  faCalendarCheck,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import FormErrorBox from "../../../Reusable/FormErrorBox";
import PhoneTextFormatter from "../../../Reusable/EveryPage/PhoneTextFormatter";
import EmailTextFormatter from "../../../Reusable/EveryPage/EmailTextFormatter";
import DateTimeTextFormatter from "../../../Reusable/EveryPage/DateTimeTextFormatter";
import DateTextFormatter from "../../../Reusable/EveryPage/DateTextFormatter";
import {
  PAGE_SIZE_OPTIONS,
  ASSOCIATE_AWAY_LOG_REASONS,
} from "../../../../Constants/FieldOptions";
import AssociateAwayLogReasonIconFormatter from "../../../Reusable/SpecificPage/AssociateAwayLog/ReasonIconFormatter";

/*
Display for both tablet and mobile.
*/
function AdminSettingAssociateAwayLogListMobile(props) {
  ////
  //// Props.
  ////

  const {
    listData,
    setPageSize,
    pageSize,
    previousCursors,
    onPreviousClicked,
    onNextClicked,
    onSelectAssociateAwayLogForDeletion,
    showDetailModalForAssociateAwayLogID,
    setShowDetailModalAssociateAwayLogID,
  } = props;

  ////
  //// Render
  ////

  return (
    <>
      {listData &&
        listData.results &&
        listData.results.map(function (datum, i) {
          return (
            <div className="mb-5">
              <hr />
              <strong>
                Associate&nbsp;
                <FontAwesomeIcon
                  className="fas"
                  icon={faArrowUpRightFromSquare}
                />
                :
              </strong>
              &nbsp;
              <Link
                target="_blank"
                rel="noreferrer"
                to={`/admin/associate/${datum.associateId}`}
              >
                {datum.associateName}
              </Link>
              <br />
              <br />
              <strong>Reason:</strong>&nbsp;
              {datum.reason === 1 ? (
                <span>{datum.reasonOther}</span>
              ) : (
                <span>
                  <AssociateAwayLogReasonIconFormatter reason={datum.reason} />
                  &nbsp;{ASSOCIATE_AWAY_LOG_REASONS[datum.reason]}
                </span>
              )}
              <br />
              <br />
              <strong>Away until:</strong>&nbsp;
              {datum.untilFurtherNotice === 1 ? (
                <span>Further notice.</span>
              ) : (
                <DateTextFormatter value={datum.untilDate} />
              )}
              <br />
              <br />
              <strong>Start Date:</strong>&nbsp;
              <DateTextFormatter value={datum.startDate} />
              <br />
              <br />
              <strong>Created At:</strong>&nbsp;
              <DateTimeTextFormatter value={datum.createdAt} />
              <br />
              <br />
              <Link
                onClick={(id) => {
                  setShowDetailModalAssociateAwayLogID(datum.id);
                }}
                className="button is-primary is-fullwidth-mobile"
                type="button"
              >
                View&nbsp;
                <FontAwesomeIcon className="mdi" icon={faChevronRight} />
              </Link>
            </div>
          );
        })}

      <div className="columns pt-4">
        <div className="column is-half">
          <span className="select">
            <select
              className={`input has-text-grey-light`}
              name="pageSize"
              onChange={(e) => setPageSize(parseInt(e.target.value))}
            >
              {PAGE_SIZE_OPTIONS.map(function (option, i) {
                return (
                  <option
                    selected={pageSize === option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
        <div className="column is-half has-text-right">
          {previousCursors.length > 0 && (
            <>
              <button
                className="button is-info is-fullwidth-mobile"
                onClick={onPreviousClicked}
              >
                Previous
              </button>
              &nbsp;
            </>
          )}
          {listData.hasNextPage && (
            <>
              <button
                className="button is-info is-fullwidth-mobile"
                onClick={onNextClicked}
              >
                Next
              </button>
            </>
          )}
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <p className="has-text-right is-size-5 pb-6">
            Total Results: {listData.count}
          </p>
        </div>
      </div>
    </>
  );
}

export default AdminSettingAssociateAwayLogListMobile;
