import React from "react";

function NotFoundError() {
  return (
    <>
      <div className="container">
        <section className="section">
          <h1>404 - Page does not exist</h1>
        </section>
      </div>
    </>
  );
}

export default NotFoundError;
