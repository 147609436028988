import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faGauge,
  faArrowRight,
  faUsers,
  faBarcode,
  faWrench,
  faCreditCard,
  faBullhorn,
  faArrowCircleRight,
  faBriefcase,
  faSearch,
  faUserTie,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { useRecoilState } from "recoil";

import { topAlertMessageState, topAlertStatusState } from "../../../AppState";

function JobSeekerDashboard() {
  ////
  //// Global state.
  ////

  const [topAlertMessage, setTopAlertMessage] =
    useRecoilState(topAlertMessageState);
  const [topAlertStatus, setTopAlertStatus] =
    useRecoilState(topAlertStatusState);

  ////
  //// Component states.
  ////

  ////
  //// API.
  ////

  ////
  //// Event handling.
  ////

  ////
  //// Misc.
  ////

  ////
  //// Misc.
  ////

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.scrollTo(0, 0); // Start the page at the top of the page.
    }

    return () => {
      mounted = false;
    };
  }, []);

  ////
  //// Component rendering.
  ////

  return (
    <>
      <div class="container">
        <section class="section">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li class="is-active">
                <Link to="/a/dashboard" aria-current="page">
                  <FontAwesomeIcon className="fas" icon={faGauge} />
                  &nbsp;Dashboard
                </Link>
              </li>
            </ul>
          </nav>
          <nav class="box">
            <div className="container">
              <div className="columns is-multiline is-mobile">
                {/* Find Work */}
                <div className="column is-6-tablet is-3-fullhd">
                  <div className="card">
                    <div className="card-image has-background-info">
                      <div
                        className="has-text-centered"
                        style={{ padding: "60px" }}
                      >
                        <FontAwesomeIcon
                          className="fas"
                          icon={faSearch}
                          style={{ color: "white", fontSize: "6rem" }}
                        />
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div className="media-content">
                          <p className="title is-4">
                            {/*<FontAwesomeIcon className="fas" icon={faSquarePhone} />&nbsp;*/}
                            Find Work
                          </p>
                        </div>
                      </div>

                      <div className="content">
                        Modify office news items.
                        <br />
                      </div>
                    </div>
                    <footer className="card-footer">
                      <Link
                        to={`/501`}
                        className="card-footer-item button is-primary is-large"
                      >
                        View&nbsp;
                        <FontAwesomeIcon
                          className="fas"
                          icon={faArrowCircleRight}
                        />
                      </Link>
                    </footer>
                  </div>
                </div>
                {/* end Find Work */}

                {/* My Documents */}
                <div className="column is-6-tablet is-3-fullhd">
                  <div className="card">
                    <div className="card-image has-background-info">
                      <div
                        className="has-text-centered"
                        style={{ padding: "60px" }}
                      >
                        <FontAwesomeIcon
                          className="fas"
                          icon={faBriefcase}
                          style={{ color: "white", fontSize: "6rem" }}
                        />
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div className="media-content">
                          <p className="title is-4">
                            {/*<FontAwesomeIcon className="fas" icon={faSquarePhone} />&nbsp;*/}
                            My Documents
                          </p>
                        </div>
                      </div>

                      <div className="content">
                        Modify office news items.
                        <br />
                      </div>
                    </div>
                    <footer className="card-footer">
                      <Link
                        to={`/501`}
                        className="card-footer-item button is-primary is-large"
                      >
                        View&nbsp;
                        <FontAwesomeIcon
                          className="fas"
                          icon={faArrowCircleRight}
                        />
                      </Link>
                    </footer>
                  </div>
                </div>
                {/* end My Documents */}

                {/* My Advisor */}
                <div className="column is-6-tablet is-3-fullhd">
                  <div className="card">
                    <div className="card-image has-background-info">
                      <div
                        className="has-text-centered"
                        style={{ padding: "60px" }}
                      >
                        <FontAwesomeIcon
                          className="fas"
                          icon={faUserTie}
                          style={{ color: "white", fontSize: "6rem" }}
                        />
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div className="media-content">
                          <p className="title is-4">
                            {/*<FontAwesomeIcon className="fas" icon={faSquarePhone} />&nbsp;*/}
                            My Advisor
                          </p>
                        </div>
                      </div>

                      <div className="content">
                        Modify office news items.
                        <br />
                      </div>
                    </div>
                    <footer className="card-footer">
                      <Link
                        to={`/501`}
                        className="card-footer-item button is-primary is-large"
                      >
                        View&nbsp;
                        <FontAwesomeIcon
                          className="fas"
                          icon={faArrowCircleRight}
                        />
                      </Link>
                    </footer>
                  </div>
                </div>
                {/* end My Advisor */}

                {/* Learning & Goals */}
                <div className="column is-6-tablet is-3-fullhd">
                  <div className="card">
                    <div className="card-image has-background-info">
                      <div
                        className="has-text-centered"
                        style={{ padding: "60px" }}
                      >
                        <FontAwesomeIcon
                          className="fas"
                          icon={faStar}
                          style={{ color: "white", fontSize: "6rem" }}
                        />
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="media">
                        <div className="media-content">
                          <p className="title is-4">
                            {/*<FontAwesomeIcon className="fas" icon={faSquarePhone} />&nbsp;*/}
                            Learning & Goals
                          </p>
                        </div>
                      </div>

                      <div className="content">
                        Modify office news items.
                        <br />
                      </div>
                    </div>
                    <footer className="card-footer">
                      <Link
                        to={`/501`}
                        className="card-footer-item button is-primary is-large"
                      >
                        View&nbsp;
                        <FontAwesomeIcon
                          className="fas"
                          icon={faArrowCircleRight}
                        />
                      </Link>
                    </footer>
                  </div>
                </div>
                {/* end Learning & Goals */}
              </div>
            </div>
          </nav>
        </section>
      </div>
    </>
  );
}

export default JobSeekerDashboard;
